.App {
  font-family: sans-serif;
  text-align: center;
  margin: 0 auto;
  padding: 0;
  max-width: 100vw;
}

body {
  margin: 0 auto;
}

* {
  box-sizing: border-box;
}

h1 {
  padding: 0;
}
